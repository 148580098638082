<template>
  <div>
      <Divider dashed ><span class="divider-text">资源检索</span></Divider>

      <Row>
          <i-col span="23">
              <label>站点周边关键字：</label>
              <i-input type="text" size="small" v-model="searchModel.aroundkeyword" clearable placeholder="多关键字用逗号隔开,如：超市,商场,电影院" ></i-input>
          </i-col>
      </Row>
      <Row class="p-b-10">
          <i-col span="15">
              <Slider v-model="searchModel.arounddistance" :max="1000" :step="50" @on-change="AdaptationRange"></Slider>
          </i-col>
          <i-col span="9" class="p-t-10 p-l-5">适配范围( <span class="text-orange">{{searchModel.arounddistance}}</span> 米)</i-col>
      </Row>
      <Row>
          <p  class="p-b-5">检索线路 (不选默认为全部线路):</p>
          <i-col span="24">
              <div v-for="(line,index) in lines"  :key="index" @click="changeCheckLines(line,index)" class="m-b-2"
                  :class="line.checked?'workplatform-radio-button-active':'workplatform-radio-button'">{{line.label}}</div>
          </i-col>
      </Row>
  </div>
</template>

<script>
import { getPublisherProductAssetList } from '@/api/product/asset'
import { getStationByAssetIds } from '@/api/product/station'

export default {
  data () {
    return ({
      lines: [],
      allStationList: [], // 原始的全部站点

      searchModel: {
        aroundkeyword: '',
        arounddistance: 200
      }
    })
  },
  created () {
    this.initPageData()
  },
  methods: {
    initPageData () {
      getPublisherProductAssetList().then(res => {
        let flag = true
        this.lines = res.map(x => {
          if (flag) {
            flag = false
            return { value: x.id, label: x.name, rimAssetIds: x.rimAssetIds, checked: true }
          } else {
            return { value: x.id, label: x.name, rimAssetIds: x.rimAssetIds, checked: false }
          }
        })

        this.getAllStations()
      })
    },
    changeCheckLines (line, i) {
      line.checked = !line.checked
      this.lines.splice(i, 1, line)
      this.handelSearch()
    },
    getAllStations () {
      const query = { assetIds: JSON.stringify(this.lines.map(x => x.value)) }
      getStationByAssetIds(query).then(res => {
        this.allStationList = res
        if (this.$store.state.stock) {
          this.$store.commit('set_stock_format_station_list', res)
          this.handelSearch()
        }
      })
    },
    handelSearch () {
      const checkLines = this.lines.filter(x => x.checked).map(x => x.value)
      const showStations = this.allStationList.filter(x => checkLines.includes(x.assetId))

      this.$store.commit('set_stock_format_search_condition', this.searchModel)
      this.$store.commit('set_stock_format_stations', showStations)
      this.$store.commit('set_stock_format_show_assets', checkLines)
      this.$store.commit('set_stock_format_update', new Date())
    },
    AdaptationRange (val) { // 查询距离改变
      if (this.searchModel.aroundkeyword !== '') {
        this.handelSearch()
      } else {
        this.$store.commit('set_stock_format_search_range', val)
      }
    }
  }
}
</script>
